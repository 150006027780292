/**
 * Created by Michał on 11.05.2017.
 */

import form from './../form';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from "photoswipe";

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let wrapper = document.querySelector('#wrapper');
    var content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
    //
}

let categorySelect = document.querySelector('.js-category-select');
let selectedOption = null;
let workTypes = document.querySelector('.js-work-type');
if (categorySelect !== null && workTypes !== null) {
    categorySelect.onchange = () => {
        selectedOption = categorySelect.options[categorySelect.selectedIndex].value;

        if (selectedOption === 'film') {
            document.querySelector('[data-type="1"]').style.display = 'block';
            document.querySelector('[data-type="2"]').style.display = 'none';
        } else if (selectedOption === 'praca-literacka' || selectedOption == 'projekt-medalu') {
            document.querySelector('[data-type="1"]').style.display = 'none';
            document.querySelector('[data-type="2"]').style.display = 'block';
        }
    };
}

var formElement = document.querySelector('.js-work-form');
if (formElement !== null) {
    form.init({
        action: '/wp-admin/admin-ajax.php',
        handler: formElement,
        data: element => {
            let data = {
                first_name: '',
                last_name: '',
                school: '',
                email: '',
                teacher_first_name: '',
                teacher_last_name: '',
                category: '',
                work_title: '',
                work_description: '',
                work_url: '',
                work_file: '',
                action: 'nt_send_form'
            };
            let first_name = element.querySelector('#author_first_name');
            let last_name = element.querySelector('#author_last_name');
            let school = element.querySelector('#school');
            let email = element.querySelector('#author_email');
            let teacher_first_name = element.querySelector('#teacher_first_name');
            let teacher_last_name = element.querySelector('#teacher_last_name');
            let category = element.querySelector('#category');
            let work_title = element.querySelector('#work_title');
            let work_description = element.querySelector('#work_description');
            let work_url = element.querySelector('#work_url');
            let work_file = element.querySelector('#work_file');

            if (first_name !== null) data.first_name = first_name.value;
            if (last_name !== null) data.last_name = last_name.value;
            if (school !== null) data.school = school.value;
            if (email !== null) data.email = email.value;
            if (teacher_first_name !== null) data.teacher_first_name = teacher_first_name.value;
            if (teacher_last_name !== null) data.teacher_last_name = teacher_last_name.value;
            if (category !== null) data.category = category.value;
            if (work_title !== null) data.work_title = work_title.value;
            if (work_description !== null) data.work_description = work_description.value;
            if (work_url !== null) data.work_url = work_url.value;
            if (work_file !== null) data.work_file = work_file.value;
            return data;
        },
        beforeSubmit: (element, hashed) => {
            let loader = element.querySelector('.loader__background');
            loader.style.display = 'flex';
            loader.querySelector('.loader').style.display = 'block';
        },
        success: (element, response) => {
            let loader = element.querySelector('.loader__background');
            let successMessage = loader.querySelector('.loader__success');
            successMessage.innerHTML = 'Dziękujemy za zgłoszenie pracy konkursowej.';
            loader.querySelector('.loader').style.display = 'none';
            element.querySelector('#author_first_name').value = '';
            element.querySelector('#author_last_name').value = '';
            element.querySelector('#school').value = '';
            element.querySelector('#author_email').value = '';
            element.querySelector('#teacher_first_name').value = '';
            element.querySelector('#teacher_last_name').value = '';
            element.querySelector('#category').value = '';
            element.querySelector('#work_title').value = '';
            element.querySelector('#work_description').value = '';
            element.querySelector('#work_url').value = '';
            if (element.querySelector('#work_file') !== null)
                element.querySelector('#work_file').value = '';
            successMessage.style.display = 'block';
            setTimeout(() => {
                // loader.style.display = 'none';
                // successMessage.style.display = 'none';
                // successMessage.innerHTML = '';
                //location.href = "https://lepsze-jutro.cf.netivo.pl/";
            }, 8000);
        },
        error: (element, response) => {
            let loader = element.querySelector('.loader__background');
            let errorMessage = loader.querySelector('.loader__error');
            console.log(response);
            errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj wysłać zgłoszenie ponownie.';
            loader.querySelector('.loader').style.display = 'none';
            errorMessage.style.display = 'block';
            setTimeout(() => {
                loader.style.display = 'none';
                errorMessage.style.display = 'none';
                errorMessage.innerHTML = '';
            }, 8000);
        }
    });
}

let modal = document.querySelector('.js-modal');
function modalHide() {
    if (modal !== null) {
        modal.style.display = 'none';
        document.body.style.overflowY = 'auto';
    }
}
function modalShow() {
    if (modal !== null) {
        modal.style.display = 'block';
        document.body.style.overflowY = 'hidden';
    }
}

let workList = document.querySelector('.js-work-list');
let workID = null;
if (workList !== null) {
    workList.querySelectorAll('.js-work-box').forEach((element) => {
        element.addEventListener('click', (e) => {
            e.preventDefault();

            workID = element.getAttribute('data-id');
            getWorkData(workID, 'nt_work_data').then(res => {
                // console.log(res);
                modal.querySelector('.modal__content').innerHTML = res;
                // modal.innerHTML = res;
                modalShow();
            }).catch(err =>{
                // console.log(err);
            });
        }, false);
    });
}
let insp_options = {
    gallery: '.js-gallery',
    children: 'a',
    pswpModule: PhotoSwipe,
};
const insp_lightbox = new PhotoSwipeLightbox(insp_options);
insp_lightbox.init();

document.addEventListener('click', (e)=> {
    if (e.target.matches('.js-modal-vote')) {
        e.preventDefault();

        if (e.target.classList.contains('work-box__button--disable')) {
            e.target.nextElementSibling.style.display = 'block';
        } else {
            let workID = e.target.getAttribute('data-id');
            getWorkData(workID, 'nt_vote').then(res => {
                console.log(res);
                e.target.nextElementSibling.style.display = 'block';
                e.target.nextElementSibling.innerHTML = 'Dziekujemy za oddanie głosu';
            }).catch(err =>{
                console.log(err);
                if (err.message) {
                    e.target.nextElementSibling.style.display = 'block';
                    e.target.nextElementSibling.innerHTML = err.message;
                }
            });
        }
    } else if (e.target.matches('.js-modal-close')) {
        e.preventDefault();

        modalHide();
    }
});

function getWorkData(id, action) {
    return fetch('/wp-admin/admin-ajax.php', {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        redirect: 'follow',
        body: 'id=' + id + '&action=' + action
    }).then(response => {
        if (!response.ok) {
            return response.json().then(function(response) {
                if (document.querySelector('.js-vote-info') !== null) {
                    document.querySelector('.js-vote-info').innerHTML = response.message;
                    document.querySelector('.js-vote-info').style.display = 'block';
                }
                throw Error(response.message);
            })
        } else {
            if (action === 'nt_work_data') return response.text();
            else return response.json();
        }
    });
}