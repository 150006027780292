import extend from './extend';
import plupload from 'plupload';

let defaultOptions = {
    handler: null,
    action: '',
    uploader: null,
    uploaded: false,
    data: form => {
        return {};
    },
    beforeSubmit: form => {},
    success: (form, response) => {},
    error: (form, error) => {}
};

const form = {
    options: {},
    init: options => {
        if (options.handler !== null && options.action.length > 0) {
            let hashed = options.handler.id;
            form.options[hashed] = extend(defaultOptions, options);
            form.handler(options.handler);
            form.options[hashed].uploader = new plupload.Uploader({
                runtimes: 'flash,silverlight,html4,html5',
                browse_button: 'pickfiles',
                container: document.getElementById('container'),

                url : "/test-upload.php",

                filters : {
                    max_file_size : '10mb',
                    mime_types: [
                        {title : "PDF files", extensions : "pdf"},
                    ]
                },

                // Flash settings
                flash_swf_url : '/plupload/js/Moxie.swf',

                // Silverlight settings
                silverlight_xap_url : '/plupload/js/Moxie.xap',
                init: {
                    PostInit: function() {
                        document.getElementById('filelist').innerHTML = '';
                    },

                    FilesAdded: function(up, files) {
                        plupload.each(files, function(file) {
                            document.getElementById('filelist').innerHTML += '<div id="' + file.id + '"><input type="hidden" value="' + file.name + '" name="work_file" id="work_file" /><span>' + file.name + '</span></div>';
                        });
                    },

                    UploadComplete: function (up, file) {
                        form.options[hashed].uploaded = true;
                        console.log(form.options[hashed].uploaded);
                        form.options[hashed].handler.querySelector('[name="send"]').click();
                        console.log(form.options[hashed].handler.querySelector('[name="send"]'));
                        //form.options[hashed].handler.submit();
                    },

                    Error: function(up, err) {
                        document.getElementById('console').innerHTML += "\nError #" + err.code + ": " + err.message;
                    }
                }
            });
            form.options[hashed].uploader.init();
        }
    },
    sendForm: (url, data) => {
        let formBody = Object.keys(data).map(key => {return key + '=' + data[key]}).join('&');
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'follow',
            body: formBody
        }).then(response => {
            console.log(response);
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.message);
                })
            } else {
                return response.json();
            }
        });
    },
    handler: (element) => {
        // let submitForm = document.querySelector(form.options.handler);
        element.addEventListener('submit', function(e) {
            e.preventDefault();
            let hashed = element.id;
            form.options[hashed].beforeSubmit(this, hashed);
            let category = this.querySelector('#category');
            if (category !== null) category = category.value;
            if (category !== null && (category === 'film' || category === 'fotostory')) form.options[hashed].uploaded = true;
            console.log(form.options[hashed].uploaded);
            if (form.options[hashed].uploaded === false ) {
                form.options[hashed].uploader.start();
            } else {
                let data = form.options[hashed].data(this);
                form.sendForm(form.options[hashed].action, data)
                    .then(response => {
                        form.options[hashed].success(this, response);
                    })
                    .catch(error => {
                        form.options[hashed].error(this, error);
                    });
            }
        });
    }
};

export default form;